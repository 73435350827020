<template>
  <div class="kt-page--fluid kt-subheader--transparent full-page">
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
          <div class="kt-header__brand-logo my-5">
            <div class="kt-container">
              <router-link :to="{ name: 'base'}"><h2 class="text-primary">Sweet Shipping Cost</h2></router-link>
            </div>

          </div>
          <div class="kt-container kt-body kt-grid kt-grid--ver page-content">
            <div class="card card-custom ">
              <div class="card-body">
                <h5>Why I should install this app?</h5>
                <p> This app estimates the cheapest shipping rate using geolocation and allows your shoppers to see the
                  shipping rates right on the product page.
                </p>

                <h5>What should I do after install?</h5>
                <p>Nothing! It’s a simple install and ready to use app! Optionally, you can change some settings in
                  Shopify admin if you need to.</p>

                <h5>What types of shipping rates do your applications support?</h5>
                <p>Applications support ONLY weight-based or price-based shipping rates. We don't calculate shipping costs
                  for Shopify Calculated rates.</p>

                <h5>I’ve made some changes in shipping rates, but it hasn't been applied yet, why?</h5>
                <p>It takes typically about 5 minutes for your changes to take effect. If things still don’t change. Also,
                app don't support calculate shipping rates (like UPS, FedEx, etc.). If the problem persists try
                  contacting our support <a href="mailto:support@sweetecom.com">support@sweetecom.com</a>.</p>

                <h5>I have 2 or more shipping rate for the order, what will widget display?</h5>
                <p>The App will show the cheapest shipping rate available.</p>

                <h5>How could I change some styles or widget position?</h5>
                <p>Try Placing: <code>{{ renderBlock }}</code> to your product template (sections/product-template.liquid)
                and you could change CSS-styles as you wish in your theme styles. If you have any trouble you can either
                  contact our support <a href="mailto:support@sweetecom.com">support@sweetecom.com</a></p>

                <h5>What should I do after uninstalling the app?</h5>
                <p>Usually, nothing, but, if you've placed the <code>{{ renderBlock }}</code>to your
                product-template.liquid, you should also delete it. Also, we will automatically delete all data about
                your previous installation.</p>

                <h5>What do I do if I encounter problems or bugs?</h5>
                <p>There shouldn’t be any, but if the app has issues try to identify the problem or bug and notify our
                  support team <a href="mailto:support@sweetecom.com">support@sweetecom.com</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent'

export default {
  name: "Faq",
  components: {
    FooterComponent
  },
  computed: {
    renderBlock() {
      return '<div id="shipping_sweetecom"></div>';
    }
  }
}
</script>

<style lang="scss">
</style>